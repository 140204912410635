
import { defineComponent, onMounted } from "vue";
import MixedWidget1 from "@/components/widgets/mixed/Widget1.vue";
import MixedWidget2 from "@/components/widgets/mixed/Widget2.vue";
import MixedWidget3 from "@/components/widgets/mixed/Widget3.vue";
import MixedWidget4 from "@/components/widgets/mixed/Widget4.vue";
import MixedWidget5 from "@/components/widgets/mixed/Widget5.vue";
import MixedWidget6 from "@/components/widgets/mixed/Widget6.vue";
import MixedWidget7 from "@/components/widgets/mixed/Widget7.vue";
import MixedWidget8 from "@/components/widgets/mixed/Widget8.vue";
import MixedWidget9 from "@/components/widgets/mixed/Widget9.vue";
import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
import MixedWidget11 from "@/components/widgets/mixed/Widget11.vue";
import MixedWidget12 from "@/components/widgets/mixed/Widget12.vue";
import MixedWidget13 from "@/components/widgets/mixed/Widget13.vue";
import MixedWidget14 from "@/components/widgets/mixed/Widget14.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "widgets-mixed",
  components: {
    MixedWidget1,
    MixedWidget2,
    MixedWidget3,
    MixedWidget4,
    MixedWidget5,
    MixedWidget6,
    MixedWidget7,
    MixedWidget8,
    MixedWidget9,
    MixedWidget10,
    MixedWidget11,
    MixedWidget12,
    MixedWidget13,
    MixedWidget14,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Mixed", ["Widgets"]);

      MenuComponent.reinitialization();
    });
  },
});
